import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import {
  setUserChefList,
  setUpdateStatus,
  onErrorStopLoad,
  setVerifyDocument,
  setContactDetail,
  setDeleteContactDetail,
  setGetHelperPages,
  setUpdateHelperPages,
  setGetSingleHelperPage,
  setUpdateHelperPageStatus,
  setDeleteHelperPage,
  setgetStats,
  setGetChatReports,
} from "../../slices/web";

function* getChatReports(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.CHAT_REPORTS}?page=${action.payload.page}&limit=${action.payload.limit}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setGetChatReports(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* getStats(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.STATS_DATA}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setgetStats(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* deleteHelperPage(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.webApiPath.DELETE_HELPER_PAGE}/${action.payload.id}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setDeleteHelperPage(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* updateHelperPageStatus(action) {
  const deleteParams = { ...action.payload };
  delete deleteParams.id;

  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.webApiPath.UPDATE_HELPER_PAGE_STATUS}/${action.payload.id}`),
      (action.payload = deleteParams)
    );
    if (resp.status) {
      yield put(setUpdateHelperPageStatus(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* updateHelperPages(action) {
  const deleteParams = { ...action.payload };
  delete deleteParams.id;

  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.webApiPath.UPDATE_HELPER_PAGES}/${action.payload.id}`),
      (action.payload = deleteParams)
    );
    if (resp.status) {
      yield put(setUpdateHelperPages(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* getSingleHelperPage(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.SINGLE_HELPER_PAGES}/${action.payload.id}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setGetSingleHelperPage(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* getHelperPages(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.HELPER_PAGES}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setGetHelperPages(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* deleteContactDetail(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.webApiPath.DELETE_CONTACT_DETAIL}/${action.payload.id}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setDeleteContactDetail(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* contactDetail(action) {
  const paramToSend = { ...action.payload };
  delete paramToSend.id;

  let mainUrl = `${ApiPath.webApiPath.CONTACT_DETAIL}?page=${action.payload.page}&limit=${action.payload.limit}`;

  if (action.payload.search) {
    mainUrl += `&search=${action.payload.search}`;
  }

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = mainUrl),
      (action.payload = paramToSend)
    );
    if (resp.status) {
      yield put(setContactDetail(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* verifyDocument(action) {
  const paramToSend = { ...action.payload };
  delete paramToSend.id;

  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.webApiPath.VERIFY_DOCUMENT}/${action.payload.id}`),
      (action.payload = paramToSend)
    );
    if (resp.status) {
      yield put(setVerifyDocument(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* userChefList(action) {
  let mainUrl = `${ApiPath.AuthApiPath.USER_CHEF_LIST}?limit=${action.payload.limit}&page=${action.payload.page}&`;
  if (action.payload.role) {
    mainUrl += `role=${action.payload.role}&`;
  }
  if (action.payload.status) {
    mainUrl += `status=${action.payload.status}&`;
  }
  if (action.payload.search) {
    mainUrl += `search=${action.payload.search}&`;
  }
  if (action.payload.documentVerified) {
    mainUrl += `documentVerified=${action.payload.documentVerified}&`;
  }
  if (action.payload.searchCountry) {
    mainUrl += `country=${action.payload.searchCountry}`;
  }

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = mainUrl),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setUserChefList(action.payload));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* updateStatus(action) {
  const paramToSend = { ...action.payload };
  delete paramToSend.id;

  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.webApiPath.UPDATE_STATUS}/${action.payload.id}`),
      (action.payload = paramToSend)
    );
    if (resp.status) {
      yield put(setUpdateStatus(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* webSaga() {
  yield all([takeLatest("web/userChefList", userChefList)]);
  yield all([takeLatest("web/updateStatus", updateStatus)]);
  yield all([takeLatest("web/verifyDocument", verifyDocument)]);
  yield all([takeLatest("web/contactDetail", contactDetail)]);
  yield all([takeLatest("web/deleteContactDetail", deleteContactDetail)]);
  yield all([takeLatest("web/getHelperPages", getHelperPages)]);
  yield all([takeLatest("web/getSingleHelperPage", getSingleHelperPage)]);
  yield all([takeLatest("web/updateHelperPages", updateHelperPages)]);
  yield all([takeLatest("web/updateHelperPageStatus", updateHelperPageStatus)]);
  yield all([takeLatest("web/deleteHelperPage", deleteHelperPage)]);
  yield all([takeLatest("web/getStats", getStats)]);
  yield all([takeLatest("web/getChatReports", getChatReports)]);
}

export default webSaga;
