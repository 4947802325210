import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  usersChefListData: [],
};

export const webSlice = createSlice({
  name: "web",
  initialState,
  reducers: {
    getChatReports: (state) => {
      state.loading = true;
    },
    setGetChatReports: (state) => {
      state.loading = false;
    },
    getStats: (state) => {
      state.loading = true;
    },
    setgetStats: (state) => {
      state.loading = false;
    },
    deleteHelperPage: (state) => {
      state.loading = true;
    },
    setDeleteHelperPage: (state) => {
      state.loading = false;
    },
    updateHelperPageStatus: (state) => {
      state.loading = true;
    },
    setUpdateHelperPageStatus: (state, action) => {
      state.loading = false;
    },

    getSingleHelperPage: (state) => {
      state.loading = true;
    },
    setGetSingleHelperPage: (state, action) => {
      state.loading = false;
    },

    getHelperPages: (state) => {
      state.loading = true;
    },
    setGetHelperPages: (state, action) => {
      state.loading = false;
    },
    updateHelperPages: (state) => {
      state.loading = true;
    },
    setUpdateHelperPages: (state, action) => {
      state.loading = false;
    },
    deleteContactDetail: (state) => {
      state.loading = true;
    },
    setDeleteContactDetail: (state, action) => {
      state.loading = false;
    },
    contactDetail: (state) => {
      state.loading = true;
    },
    setContactDetail: (state, action) => {
      state.loading = false;
    },
    verifyDocument: (state) => {
      state.loading = true;
    },
    setVerifyDocument: (state, action) => {
      state.loading = false;
    },
    updateStatus: (state) => {
      state.loading = true;
    },
    setUpdateStatus: (state, action) => {
      state.loading = false;
    },
    userChefList: (state) => {
      state.loading = true;
    },
    setUserChefList: (state, action) => {
      state.loading = false;
      state.usersChefListData = action.payload;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  userChefList,
  setUserChefList,
  updateStatus,
  setUpdateStatus,
  onErrorStopLoad,
  verifyDocument,
  setVerifyDocument,
  contactDetail,
  setContactDetail,
  deleteContactDetail,
  setDeleteContactDetail,
  getHelperPages,
  setGetHelperPages,
  updateHelperPages,
  setUpdateHelperPages,
  getSingleHelperPage,
  setGetSingleHelperPage,
  updateHelperPageStatus,
  setUpdateHelperPageStatus,
  deleteHelperPage,
  setDeleteHelperPage,
  getStats,
  setgetStats,
  getChatReports,
  setGetChatReports
} = webSlice.actions;

export default webSlice.reducer;
