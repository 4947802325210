const ApiPath = {
  AuthApiPath: {
    ADMIN_LOGIN: "/users/login",
    FORGOT_PASSWORD: "/users/forgot-password",
    RESET_PASSWORD: "/users/reset-password",
    LOGOUT_ADMIN: "/users/logOut",
    ENTER_OTP: "/users/reset-password-otp-verify",
    RSEND_ENTER_OTP: "/users/resend",
    USER_CHEF_LIST: "/users/all",
  },
  webApiPath: {
    USER_CHEF_LIST: "/users/all",
    UPDATE_STATUS: "/users/update/status",
    VERIFY_DOCUMENT: "/users/verify/documents",
    CONTACT_DETAIL: "/support_tickets/all",
    DELETE_CONTACT_DETAIL: "/support_tickets/delete",
    HELPER_PAGES: "/helperPages/all/",
    SINGLE_HELPER_PAGES: "/helperPages/get-one",
    UPDATE_HELPER_PAGES: "/helperPages/update",
    UPDATE_HELPER_PAGE_STATUS: "/helperPages/update-status",
    DELETE_HELPER_PAGE: "/helperPages/delete",
    STATS_DATA: "/users/dashboard",
    CHAT_REPORTS:"/report_chat"
  },
};

export default ApiPath;
